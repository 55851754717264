import React, { ReactNode } from "react";

interface PageContainerProps {
  children: ReactNode;
  title: string;
  description?: string;
  rightElement?: ReactNode;
}

export const PageContainer: React.FC<PageContainerProps> = ({
  children,
  title,
  description,
  rightElement,
}) => {
  return (
    <div className="flex flex-col h-full border">
      <div className="flex justify-between items-center p-4 border-b border-gray-200">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">{title}</h1>
          {description && (
            <p className="mt-1 text-sm text-gray-500">{description}</p>
          )}
        </div>
        {rightElement && (
          <div className="flex items-center">{rightElement}</div>
        )}
      </div>
      <div className="flex-1 p-4 bg-gray-50">{children}</div>
    </div>
  );
};
