import {
  ArtifactType,
  RecurrenceType,
  Scalars,
  Shipment,
  ShipmentStatus,
  ShipmentType,
  Stop,
  TaskStatus,
} from "@api/graphql/generated/generated-types";
import { DriverMapItem } from "@src/common/components/Widgets/DriverMapWidget";
import { BadgeMeta } from "../utils/stopBadgeFormatters";
import { ComponentProps, FC, ReactNode } from "react";

export enum DispatchCandidateSortingType {
  CLOSEST = "Closest",
  LEAST_DEVIATION = "Least Deviation",
  LEAST_WORKLOAD = "Least Workload",
  LEAST_TASKS = "Least Tasks",
  ALPHABETICAL = "Alphabetical",
}
export interface StopColumn {
  badgeMeta: BadgeMeta;
  timeWindowText: string | undefined;
  eta: string | undefined;
  isLikelyLate: boolean | undefined;
  taskStatusList: TaskStatus[];
  arrivedAt: string | undefined;
  completedAt: string | undefined;
  instruction: string | undefined;
  dropOffStops?: Stop[];
  pickUpStops?: Stop[];
}

export interface ArtifactDisplay {
  id: string;
  url: string;
  caption: string | undefined;
  type: ArtifactType;
  timestamp: Date;
}

export interface ArtifactsAtStop {
  stopType: string;
  stopId: string;
  stopAddress: string;
  artifacts: ArtifactDisplay[];
}

export type ShipmentRow =
  | {
      key: string;
      id: string;
      status: string;
      shipmentStatus: ShipmentStatus;
      StatusIcon: FC<ComponentProps<"svg">>;
      orderDisplayId: string | number;
      customerDisplayName: string;
      pickup: StopColumn;
      dropoff: StopColumn;
      origin: {
        dispatchZone: string;
        streetAddress: string;
        dispatchZoneLine2: string | undefined;
      };
      destination: {
        dispatchZone: string;
        streetAddress: string;
        dispatchZoneLine2: string | undefined;
      };
      package: {
        numPackages: number | undefined;
        packageSize: string;
        packageWeightInPounds: number | undefined;
      };
      shipmentDate: Scalars["Date"];
      serviceType: string;
      serviceTypeColor: string | undefined;
      driverId: string | undefined;
      driverPhotoUrl: string | undefined;
      driverName: string | undefined;
      driverMapItem: DriverMapItem | undefined;
      canUnassignDriver: boolean;
      canChangeRouting: boolean;
      canDeleteOrder: boolean;
      canFailOrder: boolean;
      canCloseOrder: boolean;
      canUncloseOrder: boolean;
      canForceCompleteOrder: boolean;
      artifactsAtStops?: ArtifactsAtStop[];
      recurrenceType: RecurrenceType | undefined;
      isCompleted: boolean;
      shipmentType: ShipmentType;
      shipment: Shipment | undefined;
      isMultiDay: boolean;
    }
  | undefined;

export enum ShipmentsPrimaryFilterType {
  ACTIVE = "ACTIVE",
  ALL = "ALL",
  LATE = "LATE",
  LATE_IN_15_MINUTES = "LATE_IN_15_MINUTES",
  LATE_IN_30_MINUTES = "LATE_IN_30_MINUTES",
  LATE_IN_60_MINUTES = "LATE_IN_60_MINUTES",
  LIKELY_LATE = "LIKELY_LATE",
  DELIVERED = "DELIVERED",
  CLOSED = "CLOSED",
  UNASSIGNED = "UNASSIGNED",
  ASSIGNED = "ASSIGNED",
}

export type TableActionDropdownItem = {
  key: string;
  label: ReactNode;
  onClick: (row: ShipmentRow) => void;
  disabled: (row: ShipmentRow) => boolean;
  icon: React.ReactNode;
  tooltip: (row: ShipmentRow) => string | undefined;
};

export interface ShipmentOverviewCounts {
  numLate: number | undefined;
  numLateIn15Minutes: number | undefined;
  numLateIn30Minutes: number | undefined;
  numLateIn60Minutes: number | undefined;
  numLikelyLate: number | undefined;
}
